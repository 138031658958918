<template>
  <div>
    <v-card :elevation="0" class="v-sheet theme--light br-0">
      <div class="row mt-0 mb-0">
        <div
          class="col-12 col-md-12 m-0 p-0"
          style="margin-top: -5px !important;"
        >
          <DivisorColor :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`" />
        </div>
      </div>

      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12" sm="12" md="8">
            Solicitudes de cambio de estado
          </v-col>
          <v-col cols="12" sm="12" md="4"> </v-col>
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="solicitudes"
          :search="search"
          :loading="tableLoading"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            'items-per-page-text': 'Registros por página',
            pageText: '{0}-{1} de {2}'
          }"
        >
          <template v-slot:top>
            <!-- v-container, v-col and v-row are just for decoration purposes. -->
            <v-container fluid>
              <v-row>
                <v-col cols="6" md="6" sm="6" xs="6">
                  <v-text-field
                    autocomplete="off"
                    class=""
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="search"
                    label="Código/Nombre del proyecto"
                    maxlength="200"
                  >
                    <v-icon slot="append" color="blue-grey darken-2"
                      >mdi-magnify</v-icon
                    >
                  </v-text-field>
                </v-col>

                <v-col cols="6" md="6" sm="6" xs="6">
                  <v-select
                    v-model="filtroTipoSolicitud"
                    :items="estadosSolicitud"
                    :loading="false"
                    dense
                    filled
                    item-text="estado"
                    item-value="id"
                    label="Tipo de control"
                    :no-data-text="'No existen estados registrados'"
                    menu-props="auto"
                    @change="obtenerSolicitudes(filtroTipoSolicitud)"
                  ></v-select>
                </v-col>

                <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                  <v-btn
                    class="ma-0 white--text d-none"
                    medium
                    color="light-blue darken-2"
                  >
                    <v-icon left>mdi-magnify</v-icon> Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.fechaCreacion | formatDate }}</td>
              <td>{{ item.codigo }}</td>
              <td>{{ item.nombreProyecto }}</td>
              <td>{{ item.estadoSolicitado }}</td>
              <td>
                <v-chip
                  class="ma-2 font-weight-medium"
                  label
                  :color="
                    item.estadosId === 1
                      ? 'blue-greye lighten-4'
                      : item.estadosId === 2
                      ? 'deep-orange lighten-4'
                      : item.estadosId === 4
                      ? 'light-blue lighten-4'
                      : item.estadosId === 6
                      ? 'blue lighten-4'
                      : item.estadosId === 5
                      ? 'deep-orange lighten-4'
                      : 'pink lighten-4'
                  "
                  :text-color="
                    item.estadosId === 1
                      ? 'blue-grey lighten-1'
                      : item.estadosId === 2
                      ? 'deep-orange lighten-1'
                      : item.estadosId === 4
                      ? 'light-blue lighten-1'
                      : item.estadosId === 6
                      ? 'blue darken-1'
                      : item.estadosId === 5
                      ? 'deep-orange lighten-1'
                      : 'pink darken-1'
                  "
                  small
                >
                  {{ item.estadosId === 1 ? `Recibida` : item.estadosId === 4 ? `Aprobada` : item.estado }}
                </v-chip>
              </td>

              <td>
                <v-btn
                  v-if="item.estadosId === 1"
                  class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="
                    mostrarDialogAprobar(
                      1,
                      item.id,
                      item.codigo,
                      item.nombreProyecto,
                      item.estadoActual,
                      item.estadoActualId,
                      item.estadoSolicitado,
                      item.estadoSolicitadoId,
                      item.proyectosCNSId
                    )
                  "
                >
                  <v-icon left>mdi-check-all</v-icon> Aprobar cambio de estado
                </v-btn>

                <v-btn
                  v-if="item.estadosId === 1"
                  class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="
                    mostrarDialogAprobar(
                      2,
                      item.id,
                      item.codigo,
                      item.nombreProyecto,
                      item.estadoActual,
                      item.estadoActualId,
                      item.estadoSolicitado,
                      item.estadoSolicitadoId,
                      item.proyectosCNSId
                    )
                  "
                >
                  <v-icon left>mdi-window-close</v-icon> Rechazar cambio de estado
                </v-btn>

                <v-btn
                  class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="descargarArchivo(item.pathDocumentoRespaldo)"
                >
                  <v-icon left>mdi-cloud-download</v-icon> Descargar respaldo solicitud
                </v-btn>

                <v-btn v-if="item.estadosId == 4 && item.pathDocumentoInforme"
                  class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="descargarArchivo(item.pathDocumentoInforme)"
                >
                  <v-icon left>mdi-cloud-download</v-icon> Descargar informe
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialogDetalleUsuarios"
      max-width="1024px"
      persistent
      transition="scroll-y-transition"
    >
      <v-card scrollable>
        <v-card-title>
          Detalle de la solicitud {{ detalleSolicitud.codigoSolicitud }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              dialogDetalleUsuarios = false;
              resetDetalleSolicitud();
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mb-0"></v-divider>

        <v-card-text class="p-10 pt-1">
          <div v-if="skeletonDetalle">
            <v-row>
              <v-col cols="12" md="12">
                <v-skeleton-loader
                  v-bind="attrs"
                  min-height="600px"
                  type="article, article, article, actions"
                >
                </v-skeleton-loader>
              </v-col>
            </v-row>
          </div>

          <div v-if="!skeletonDetalle">
            <v-row class="mt-1">
              <v-col cols="12" md="12" sm="12" xs="12" class="pb-0">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6"
                      >Fecha de la solicitud</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-subtitle-1">{{
                      detalleSolicitud.fechaCreacion | formatDate
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="12" sm="12" xs="12" class="pb-0">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6"
                      >Tipo de solicitud</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-subtitle-1">{{
                      detalleSolicitud.tipoSolicitud
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6"
                      >Institución</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-subtitle-1">{{
                      detalleSolicitud.entidad
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6"
                      >Unidad Ejecutora</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-subtitle-1">{{
                      detalleSolicitud.unidadEjecutora
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6"
                      >Nombre del solicitante</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-subtitle-1">{{
                      detalleSolicitud.nombreSolicitante
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6"
                      >Correo del solicitante</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-subtitle-1">{{
                      detalleSolicitud.correoSolicitante
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6"
                      >Nombre de la Máxima Autoridad</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-subtitle-1">{{
                      detalleSolicitud.nombreMaximaAutoridad
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>

            <v-row class="mt-8">
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-subheader class="text-h6 black--text">
                  Usuarios solicitados
                </v-subheader>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-2 pb-3">
                <v-data-table
                  class="elevation-1"
                  :headers="headersUsuariosResumen"
                  :items="usuariosSolicitud"
                  :loading="tableDetallesLoading"
                  hide-default-footer
                  no-data-text="No hay usuarios agregados para la solicitud"
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-page-first',
                    lastIcon: 'mdi-page-last',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    'items-per-page-text': 'Registros por página',
                    pageText: '{0}-{1} de {2}',
                  }"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <!-- <td>{{ item.municipio ? item.municipio + ", " :  ""}} {{item.departamento}}</td> -->
                      <td>{{ item.nombres }} {{ item.apellidos }}</td>
                      <td>{{ item.correo }}</td>
                      <td>{{ item.cargo }}</td>
                      <td>{{ item.proyecto }}</td>
                      <td>{{ item.estadosId === 3 ? item.usuario : `N/A` }}</td>
                      <td>
                        <v-chip
                          class="ma-2 font-weight-medium"
                          label
                          :color="
                            item.estadosId === 1
                              ? 'blue-grey lighten-4'
                              : item.estadosId === 2
                              ? 'deep-orange lighten-4'
                              : item.estadosId === 3
                              ? 'blue lighten-4'
                              : item.estadosId === 4
                              ? 'teal lighten-4'
                              : item.estadosId === 4
                              ? 'deep-orange lighten-4'
                              : 'pink lighten-4'
                          "
                          :text-color="
                            item.estadosId === 1
                              ? 'blue-grey lighten-1'
                              : item.estadosId === 2
                              ? 'deep-orange lighten-1'
                              : item.estadosId === 3
                              ? 'blue lighten-1'
                              : item.estadosId === 4
                              ? 'teal lighten-1'
                              : item.estadosId === 4
                              ? 'deep-orange lighten-1'
                              : 'pink darken-1'
                          "
                          small
                        >
                          {{
                            item.estadosId === 1
                              ? `Solicitado`
                              : item.estadosId === 3
                              ? `Vinculado`
                              : item.estado
                          }}
                        </v-chip>
                      </td>

                      <td>
                        <v-btn
                          v-if="item.estadosId === 1"
                          class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                          small
                          depressed
                          color="blue-grey lighten-5"
                          @click="
                            generarUsuario(
                              detalleSolicitud.id,
                              item.id,
                              item.nombres,
                              item.apellidos,
                              item.correo,
                              item.telefonos,
                              item.area,
                              item.cargo,
                              item.tipoProyecto,
                              item.proyectoId
                            )
                          "
                        >
                          <v-icon left>mdi-account-check</v-icon> Vincular
                          usuario
                        </v-btn>

                        <v-tooltip top v-if="item.observaciones">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                              small
                              depressed
                              color="blue-grey lighten-5"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon left
                                >mdi-file-document-edit-outline</v-icon
                              >

                              Observaciones
                            </v-btn>
                          </template>
                          <span>{{ item.observaciones }}</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </template>

                  <!-- <template v-slot:[`item.entidad`]="{ item }">
                                      {{item.entidad}}, {{item.unidadEjecutora}}
                                  </template> -->
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--begin:: dialog rechazo de solicitud -->
    <v-dialog
      v-model="dialogRechazar"
      width="800"
      transition="scroll-y-transition"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 lighten-2">

          <p class="black--text pb-0 mb-1" style="width: 100%;"> {{ tituloAproparRechazar }}</p>
        
          <p class="grey--text pt-0">{{datosSolicitudRechazo.estadoSolicitado }}</p> 
        
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnEstadoLoading"
            @click="
              dialogRechazar = false;
              resetForm();
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>
          <v-row> 
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-subheader class="text-h6 black--text">
                Datos de la solicitud
                <span class="text-subtitle-1 red--text text--darken-1"> </span>
              </v-subheader>
            </v-col>
            <v-col
              cols="12"
              md="12"
              sm="12"
              xs="12"
              class="pt-0 pb-2 pt-0 pb-0"
            >
              <v-list-item>
                <v-list-item-content class="pt-0 pb-0">
                  <v-list-item-title class="black--text text--darken">Código del proyecto</v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1">
                    {{ datosSolicitudRechazo.codigoProyecto }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col
              cols="12"
              md="12"
              sm="12"
              xs="12"
              class="mt-0 mb-0 pt-0 pb-0"
            >
              <v-list-item>
                <v-list-item-content class="pt-0 pb-0">
                  <v-list-item-title
                    >Nombre del programa / proyecto
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1" style="white-space: normal !important;">
                    {{ datosSolicitudRechazo.nombreProyecto }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>

            <v-col
              cols="12"
              md="6"
              sm="6"
              xs="12"
              class="mt-0 mb-0 pt-2 pb-0"
            >
              <v-list-item>
                <v-list-item-content class="pt-0 pb-0">
                  <v-list-item-title
                    >Estado actual del programa / proyecto
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1">
                    {{ datosSolicitudRechazo.estadoActual }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>

            <v-col cols="12" md="6" sm="6" xs="12" class="mt-0 mb-0 pt-2 pb-0">
              <v-list-item>
                <v-list-item-content class="pt-0 pb-0">
                  <v-list-item-title
                    >Estado solicitado para el programa / proyecto
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1">
                    {{ datosSolicitudRechazo.estadoSolicitado }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>

          <v-row class="pt-6">
            <v-col cols="12" md="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-form
            ref="form"
            v-on:submit.prevent="cargarArchivo"
            v-model="validForm"
          >
            <v-row v-if="datosSolicitudRechazo.accion == 1">
              <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                <v-menu
                  ref="menuFechaSolicitud"
                  v-model="menuFechaSolicitud"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      filled
                      color="blue-grey lighten-2"
                      v-model="computedFechaSolicitud"
                      :label="textoFechaSolicitud"
                      hint="DD/MM/AAAA"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      :rules="[
                        datosSolicitudRechazo.accion == 1
                          ? required('fecha suscripción')
                          : true
                      ]"
                      v-mask="'##/##/####'"
                      maxlength="10"
                      :disabled="false"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateFechaSolicitud"
                    no-title
                    @input="menuFechaSolicitud = false"
                    locale="es"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                <v-menu
                  ref="menuFechaInforme"
                  v-model="menuFechaInforme"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      filled
                      color="blue-grey lighten-2"
                      v-model="computedFechaInforme"
                      :label="textoFechaInforme"
                      hint="DD/MM/AAAA"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      :rules="[
                        datosSolicitudRechazo.accion == 1
                          ? required('fecha suscripción')
                          : true
                      ]"
                      v-mask="'##/##/####'"
                      maxlength="10"
                      :disabled="false"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateFechaInforme"
                    no-title
                    @input="menuFechaInforme = false"
                    locale="es"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row v-if="datosSolicitudRechazo.accion == 1">
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <template>
                  <v-file-input
                    id="docFile"
                    dense
                    filled
                    placeholder="Seleccionar archivo .pdf/jpg"
                    :label="textoArchivoCarga"
                    append-icon="mdi-file-pdf-outline1"
                    prepend-icon=""
                    accept="application/pdf,image/jpeg"
                    @change="onFileChange"
                    :rules="[datosSolicitudRechazo.accion == 1 ? fileRequired('documento') : true]"
                    :show-size="1000"
                    ref="docFile"
                    class="required"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip v-if="index < 2" small label color="primary">
                        {{ text }}
                      </v-chip>
                      <span
                        v-else-if="index === 2"
                        class="
                                        text-overline
                                        grey--text
                                        text--darken-3
                                        mx-2
                                    "
                      >
                        +{{ files.length - 2 }} archivo(s)
                      </span>
                    </template>
                  </v-file-input>
                </template>
              </v-col>
            </v-row>

            <v-row class="mt-4">
              <v-col
                cols="12"
                md="12"
                sm="12"
                xs="12"
                class="mt-0 mb-0 pt-0 pb-0 pl-2 pr-2"
              >
                <v-textarea
                  autocomplete="off"
                  :class="datosSolicitudRechazo.accion === 2 ? `required` : ``"
                  dense
                  filled
                  color="blue-grey lighten-2"
                  v-model="datosSolicitudRechazo.observaciones"
                  label="Observaciones de analista"
                  rows="3"
                  maxlength="2000"
                ></v-textarea>
              </v-col>

              <v-col
                cols="12"
                md="12"
                sm="12"
                xs="12"
                class="mt-0 mb-0 pt-0 pb-0 pl-2 pr-2"
                v-if="datosSolicitudRechazo.accion == 1"
              >
                <v-textarea
                  autocomplete="off"
                  class=""
                  dense
                  filled
                  color="blue-grey lighten-2"
                  v-model="datosSolicitudRechazo.otrasObservaciones"
                  label="Otras observaciones"
                  rows="3"
                  maxlength="2000"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <p class="text-subtitle-1">{{ textoAprobarRechazar }}</p>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row class="pt-2">
              <v-col cols="12" md="12">
                <v-spacer></v-spacer>
                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right"
                  type="submit"
                  dark
                  :disabled="!validForm || (
                    datosSolicitudRechazo.accion === 2 &&
                      datosSolicitudRechazo.observaciones.length <= 0)
                  "
                  :loading="btnEstadoLoading"
                >
                  Sí, continuar
                </v-btn>
                <v-btn
                  color="grey lighten-5"
                  elevation="0"
                  class="mb-2 float-right grey lighten-5 mr-2"
                  @click="dialogRechazar = false"
                  :disabled="btnEstadoLoading"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--end: dialog-->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
      transition="scroll-y-transition"
    ></DialogLoader>
    <!---->
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DivisorColor from "@/view/content/biblioteca-componentes/DivisorColor.vue";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DialogLoader from "@/view/content/DialogLoader";
import validations from "@/core/untils/validations.js";
import DownloadFile from "@/core/untils/downloadfile.js";
import { CARGAR_ARCHIVO } from "@/core/services/store/cargaarchivo.module";
import {
  OBTENER_SOLICITUDES_CAMBIO_ESTADO_CNS,
  OPERAR_SOLICITUD_CAMBIO_ESTADO_CNS
} from "@/core/services/store/proyectoscns/solicitudescambioestado/solicitudcambioestado.module";
//import { OBTENER_SUBITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";

moment.locale("es");
Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

export default {
  name: "SolicitudCambioEstadoProyectosCNS",
  components: {
    DivisorColor,
    SnackAlert,
    DialogLoader,
  },
  data() {
    return {
      dateFechaSolicitud: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      ).toISOString()
        .substr(0, 10),
      dateFechaInforme: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      ).toISOString()
        .substr(0, 10),
      tableLoading: false,
      tableDetallesLoading: false,
      solicitudes: [],
      detalleSolicitud: {},
      usuariosSolicitud: [],
      search: "",
      dialogDetalleUsuarios: false,
      dialogLoaderVisible: false,
      dialogLoaderText: "",
      datosUsuario: {},
      skeletonDetalle: false,
      dialogRechazar: false,
      tituloAproparRechazar: "",
      textoAprobarRechazar: "",
      datosSolicitudRechazo: {
        codigoSolicitud: "",
        fecha: "",
        institucion: "",
        motivoRechazo: "",
        accion: 0,
        observaciones: "",
        otrasObservaciones: "",
        pathDocumentoInforme: "",
        fechaSolicitud: "",
        fechaInforme: "",
      },

      btnEstadoLoading: false,
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 0,
      },
      ...validations,
      menuFechaSolicitud: false,
      menuFechaInforme: false,
      textoFechaSolicitud: "",
      textoFechaInforme: "",
      textoArchivoCarga: "",
      archivo: [],
      validForm: false,
      estadosSolicitud: [
        { id: 1, estado: "Control de cierre y liquidación" },
        { id: 2, estado: "Control de suspensión" },
        { id: 3, estado: "Control de finalización" }
      ],
      archivoCargado: false,
      filtroTipoSolicitud: 0
    };
  },

  methods: {
    resetForm() {
      
      if (this.datosSolicitudRechazo.accion == 1) {
        if(this.$refs.docFile) {
          document.querySelector("#docFile").value = "";
          this.$refs.docFile.reset();
        }
      }

      this.datosSolicitudRechazo = {
        id: 0,
        codigoSolicitud: "",
        fecha: "",
        institucion: "",
        motivoRechazo: "",
      };
    },
    onFileChange(e) {
      if (e) {
        //!?Validar que el tipo de archivo sea pdf
        if (e.type != "application/pdf" && e.type != "image/jpeg") {
          // Api call
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El archivo que desea ingresar no es válido.`
          );
          //console.log("no es un pdf..")
          //this.$refs.file.reset();
          document.querySelector("#docFile").value = "";
          this.$refs.docFile.reset();
          return false;
        }
        //?Validar que el tamaó del archivo no exceda los 10Mb
        if (e.size / 1024 / 1024 > 20) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El tamaño del archivo excede el límite permitido (20Mb)`
          );
          // this.$refs.file.reset();
          document.querySelector("#docFile").value = "";
          this.$refs.docFile.reset();
          return false;
        }

        this.archivo = e;
      }
    },

    //Obtener las solicitudes registradas
    async obtenerSolicitudes(tipoSolicitud) {
      this.tableLoading = true;
      this.solicitudes = [];
      await this.$store
        .dispatch(OBTENER_SOLICITUDES_CAMBIO_ESTADO_CNS, {
          tipoSolicitud: tipoSolicitud,
          estadoId: 0
        })
        .then(res => {
          if (res.status === 200) {
            this.solicitudes = res.data;
          }
          this.tableLoading = false;
        })
        .catch(() => {
          this.solicitudes = [];
          this.tableLoading = false;
        });
    },

    //Obtener los detalles de solicitud específica
/*     async obtenerDetallesSolicitud(solicitudId) {
      this.skeletonDetalle = true;

      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Obteniendo detalles de la solicitud....";
      this.detalleSolicitud = {};
      await this.$store
        .dispatch(OBTENER_SOLICITUD_USUARIOS, solicitudId)
        .then(res => {
          if (res.status === 200) {
            this.detalleSolicitud = res.data;
            this.usuariosSolicitud = this.detalleSolicitud.usuarios;
            this.dialogDetalleUsuarios = true;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.dialogLoaderVisible = false;
          this.skeletonDetalle = false;
        })
        .catch(() => {
          this.detalleSolicitud = {};
          this.dialogLoaderVisible = false;
          this.skeletonDetalle = false;
        });
    },
 */
    resetDetalleSolicitud() {
      this.detalleSolicitud = {};
    },

    async generarUsuario(
      solicitudId,
      detalleId,
      nombres,
      apellidos,
      correo,
      telefonos,
      area,
      cargo,
      tipoProyecto,
      proyectoId
    ) {
      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Generando usuario, por favor espere....";
      this.datosUsuario.nombres = nombres;
      this.datosUsuario.apellidos = apellidos;
      this.datosUsuario.email = correo;
      this.datosUsuario.telefonos = telefonos;
      this.datosUsuario.area = area;
      this.datosUsuario.cargo = cargo;
      this.datosUsuario.tipoProyecto = tipoProyecto;
      this.datosUsuario.entidadId = this.detalleSolicitud.entidadId;
      this.datosUsuario.proyectoId = proyectoId;
      this.datosUsuario.detalleSolicitudId = detalleId;

      await this.$store
        .dispatch(OPERAR_SOLICITUD_CAMBIO_ESTADO_CNS, {
          datos: this.datosUsuario
        })
        .then(res => {
          this.btnLoading = false;
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            // this.nombreUsuario = this.datosUsuario.nombres;
            //this.dialogDetalleUsuarios = false;
            // this.usuariosSolicitud=[];
            this.datosUsuario = {};
            this.obtenerDetallesSolicitud(solicitudId);
            this.obtenerSolicitudes(this.filtroTipoSolicitud);
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );

            this.datosSolicitudRechazo = {
              codigoSolicitud: "",
              fecha: "",
              institucion: "",
              motivoRechazo: "",
              accion: 0,
              observaciones: "",
              otrasObservaciones: "",
              pathDocumentoInforme: "",
              fechaSolicitud: "",
              fechaInforme: "",
            };

            this.archivo = [];
            document.querySelector("#docFile").value = "";
            this.$refs.docFile.reset();
            //this.btnEstadoLoading = false;
            //this.resetForm();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.dialogLoaderVisible = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
          this.dialogLoaderVisible = false;
        });

      //console.log(solicitudId + ` ` + detalleId)
      return;
    },

    //Mostrar el dialog para aprobar o rechazar la solicitud
    //Accion 1 = Aprobar, 2 rechazar
    mostrarDialogAprobar(
      accion,
      solicitudId,
      codigoProyecto,
      nombreProyecto,
      estadoActual,
      estadoActualId,
      estadoSolicitado,
      estadoSolicitadoId,
      proyectoId
    ) {
      this.datosSolicitudRechazo = {
        accion: accion,
        solicitudId: solicitudId,
        codigoProyecto: codigoProyecto,
        nombreProyecto: nombreProyecto,
        estadoSolicitado: estadoSolicitado,
        estadoActual: estadoActual,
        estadoSolicitadoId: estadoSolicitadoId,
        proyectoId: proyectoId,
        observaciones: ""
      };

      // console.log(estadoSolicitadoId)
      if (estadoSolicitadoId == 4) {
        this.textoArchivoCarga =
          "Cargar el informe de Cierre/Liquidación del Programa / Proyecto";
        this.textoFechaSolicitud =
          "Fecha de Cierre/Liquidación del Programa/Proyecto";
        this.textoFechaInforme =
          "Fecha informe de Cierre/Liquidación del Programa/Proyecto";
      }

      if (estadoSolicitadoId == 6) {
        this.textoArchivoCarga =
          "Cargar el informe de Finalización del Programa / Proyecto";
        this.textoFechaSolicitud =
          "Fecha de finalización del Programa/Proyecto";

        this.textoFechaInforme =
          "Fecha informe de Finalización del Programa/Proyecto";
      }

      if (estadoSolicitadoId == 8) {
        this.textoArchivoCarga =
          "Cargar el informe de suspensíón del Programa / Proyecto";

        this.textoFechaSolicitud =
          "Fecha de finalización del Programa/Proyecto";
        this.textoFechaInforme =
          "Fecha informe de cierre del Programa/Proyecto";
      }

      if (accion === 1) {
        this.tituloAproparRechazar = "Aprobar cambio de estado";
        this.textoAprobarRechazar = `¿Está seguro de aprobar la solicitud?`;
      } else {
        this.tituloAproparRechazar = "Rechazar cambio de estado";
        this.textoAprobarRechazar = `¿Está seguro de rechazar la solicitud?`;
      }

      this.dialogRechazar = true;
    },

    async rechazarSolicitud() {
      if (
        this.datosSolicitudRechazo.observaciones.length <= 0 &&
        this.datosSolicitudRechazo.accion === 2
      ) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `Debe describir observaciones para el rechazo de la soliciutd`
        );
        return;
      }

      if (this.datosSolicitudRechazo.accion == 1) {
        this.datosSolicitudRechazo.fechaInforme = moment(
          this.computedFechaInforme,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");

        this.datosSolicitudRechazo.fechaSolicitud = moment(
          this.computedFechaSolicitud,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
      } else {
        this.datosSolicitudRechazo.fechaSolicitud = null;
        this.datosSolicitudRechazo.fechaInforme = null;
        this.datosSolicitudRechazo.otrasObservaciones = "";
      }

      this.btnEstadoLoading = true;
      this.$store
        .dispatch(
          OPERAR_SOLICITUD_CAMBIO_ESTADO_CNS,
          this.datosSolicitudRechazo
        )
        .then(res => {
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );

            this.obtenerSolicitudes(this.filtroTipoSolicitud);
            this.resetForm();
            this.dialogRechazar = false;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEstadoLoading = false;
        })
        .catch(error => {
          this.btnEstadoLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    async cargarArchivo() {
      const files = this.archivo;
      let path = "";
      this.btnEstadoLoading = true;
      // this.datosArchivo={};
      //Definir la ruta segun el tipo de archivo
      if (this.datosSolicitudRechazo.accion == 2) {
        this.rechazarSolicitud();
        return;
      }

      path = "\\cns\\documentos\\control-estado\\";

      await this.$store
        .dispatch(CARGAR_ARCHIVO, { file: files, path: path })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            this.archivoCargado = true;

            //Agregar el path en función del tipo de archivo que se cargó

            this.datosSolicitudRechazo.pathDocumentoInforme = res.pathArchivo;
            //this.datosArchivo.path = res.pathArchivo;

            //this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.mensaje);
            this.rechazarSolicitud();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.mensaje
            );
            this.archivoCargado = false;
            this.btnEstadoLoading = false;
          }
          //this.btnEstadoLoading=false;
        })
        .catch(error => {
          this.$refs.snackalert.SnackbarShow("warning", "Mensaje", error);
          this.btnEstadoLoading = false;
        });
    },

    //!Descargar el desde server
    descargarArchivo(path) {
      DownloadFile.download(path);
    },

    formatDatePicker(date) {
      //console.log(date)
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    }
  },
  created() {
    this.filtroTipoSolicitud = 1;
    this.obtenerSolicitudes(this.filtroTipoSolicitud);
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "/" },
      { title: "Proyectos CNS" },
      { title: "Solicitudes Cambio Estado" }
    ]);
  },

  computed: {
    computedFechaSolicitud: {
      get() {
        //console.log("asdf")
        return this.formatDatePicker(this.dateFechaSolicitud);
      },
      set(newValue) {
        //console.log(newValue)
        return this.formatDatePicker(
          moment(newValue, "DD/MM/YYYY").format("YYYY-MM-DD")
        );
      },
    },
    computedFechaInforme: {
      get() {
        //console.log("asdf")
        return this.formatDatePicker(this.dateFechaInforme);
      },
      set(newValue) {
        //console.log(newValue)
        return this.formatDatePicker(
          moment(newValue, "DD/MM/YYYY").format("YYYY-MM-DD")
        );
      },
    },
    headers() {
      return [
        {
          text: "Fecha de registro",
          align: "left",
          sortable: true,
          value: "fechaCreacion"
        },
        {
          text: "Código Proyecto",
          align: "left",
          sortable: true,
          value: "codigo"
        },
        {
          text: "Nombre del Proyecto",
          align: "left",
          sortable: true,
          value: "nombreProyecto"
        },
        {
          text: "Estado Solicitado",
          align: "left",
          sortable: true,
          value: "nombreEstado"
        },
        { text: "Estado de la solicitud", value: "estado" },
        { text: "Acciones", value: "iron" }
      ];
    },

    headersUsuariosResumen() {
      return [
        { text: "Nombre", value: "nombres" },
        { text: "Correo", value: "correo" },
        { text: "Área y Cargo", value: "area" },
        /*  { text: "Institución, Unidad Ejecutora", value: "entidad"},
         */ { text: "Proyecto", value: "proyecto" },
        { text: "Usuario", value: "usuario" },
        { text: "Estado", value: "estadosId" },

        { text: "", value: "accion" }
      ];
    }
  }
};
</script>
